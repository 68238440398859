import React, { useEffect, useState } from 'react';
import './AdminPage.css';
import { useTelegram } from "../../../hooks/useTelegram";
import * as client from "../../../utils/client";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {useJwt} from "../../../context/JwtContext";

const AdminPage = () => {
    const {jwtToken, user} = useJwt();
    const { telegram } = useTelegram();
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    useEffect(() => {
        client.getUsers()
            .then((usersJson) => {
                setUsers(usersJson);
                setFilteredUsers(usersJson);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        const filtered = users.filter((user) =>
            user.username.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleRoleChange = (userId, newRole) => {
        const updatedUsers = users.map((user) => {
            if (user.id === userId) {
                return { ...user, role: newRole };
            }
            return user;
        });
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        client.updateUserRole(userId, newRole)
            .then(() => {
                console.log(`User role updated to ${newRole}`);
            })
            .catch((err) => {
                console.error("Failed to update user role:", err);
            });
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="page-container">
            <h1>User Management</h1>

            <div className='search-bar'>
                <input
                    type="text"
                    placeholder="Search by username"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="search-input"
                />
            </div>

            <table className="users-table">
                <thead>
                <tr>
                    <th>Username</th>
                    <th>Role</th>
                </tr>
                </thead>
                <tbody>
                {filteredUsers.map((user) => (
                    <tr key={user.id}>
                        <td>{user.username}</td>
                        <td>
                            <select
                                value={user.role}
                                onChange={(e) => handleRoleChange(user.id, e.target.value)}
                            >
                                <option value="USER">USER</option>
                                <option value="REVIEWER">REVIEWER</option>
                                <option value="ADMIN">ADMIN</option>
                            </select>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminPage;
