export const ProblemTheme = {
    ALGEBRA: 'Алгебра',
    GEOMETRY: 'Геометрия',
    NUMBER_THEORY: 'Теория чисел',
    COMBINATORICS: 'Комбинаторика'
};

export const ProblemType = {
    JUNIOR: 'Младшие (5-7 кл.)',
    LIST: 'Перечень',
    CLASSIC: 'Классика'
};
