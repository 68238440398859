import React, {useEffect, useRef, useState} from 'react';
import './ReviewsPage.css';
import * as client from "../../../utils/client";
import * as constants from "../../../constants/constants";
import {Link} from "react-router-dom";
import AddReviewForm from '../../form/AddReviewForm/AddReviewForm';
import {isPrivilegeUser} from "../../../utils/userUtil";
import {useTelegram} from "../../../hooks/useTelegram";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import {useJwt} from "../../../context/JwtContext";

const ReviewsPage = () => {
    const {jwtToken, user} = useJwt();
    const [showDeferredPool, setShowDeferredPool] = useState(false);
    const [deferredProblems, setDeferredProblems] = useState([]);
    const [showProblemModal, setShowProblemModal] = useState(false);
    const [userReviewList, setUserReviewList] = useState([]);
    const [showReviewHistory, setShowReviewHistory] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);
    const [problem, setProblem] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState('ANY');
    const [selectedType, setSelectedType] = useState('ANY');

    const {telegram} = useTelegram();

    const modalContentRef = useRef(null);

    useEffect(() => {
        telegram.BackButton.hide();
    }, [telegram.BackButton]);

    useEffect(() => {
        client.getReviewByUserId()
            .then((userReviewJson) => {
                setUserReviewList(userReviewJson);
            })
            .catch(err => {
                console.log(err);
            });
    }, [user.id]);

    useEffect(() => {
        client.getDeferredProblems()
            .then(deferredProblemJson => {
                setDeferredProblems(deferredProblemJson);
            })
            .catch(err => {
                console.log(err);
            });
    }, [user.tgId]);

    useEffect(() => {
        if (showProblemModal && modalContentRef.current) {
            modalContentRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, [problem, showProblemModal]);

    if (!user || !isPrivilegeUser(user)) {
        return null;
    }

    const getRandomProblem = () => {
        const theme = selectedTheme !== 'ANY' ? selectedTheme : null;
        const type = selectedType !== 'ANY' ? selectedType : null;

        client.getRandomProblem(theme, type)
            .then(randomProblemJson => {
                if (!randomProblemJson) {
                    alert('Задачи кончились!');
                } else {
                    setProblem(randomProblemJson);
                    setShowProblemModal(true);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleDeferredProblemSelect = (selectedProblem) => {
        setProblem(selectedProblem);
        setShowDeferredPool(false);
        setShowProblemModal(true);
    };

    const handleAddReview = (reviewData) => {
        const reviewCredentials = {
            tgId: user.tgId,
            problemId: problem.id,
            text: reviewData.text,
            beauty: reviewData.beauty,
            complexity: reviewData.complexity,
            wellKnown: reviewData.wellKnown,
            sourceUrl: reviewData.sourceUrl,
            tag: reviewData.tag
        };

        client.addReview(reviewCredentials)
            .then((review) => {
                setDeferredProblems(prevDeferredProblems =>
                    prevDeferredProblems.filter(p => p.id !== problem.id)
                );
                setUserReviewList([...userReviewList, review]);

                if (deferredProblems.length > 1) {
                    const nextProblem = deferredProblems.find(p => p.id !== problem.id);
                    if (nextProblem) {
                        setProblem(nextProblem);
                    } else {
                        setShowProblemModal(false);
                    }
                } else {
                    setShowProblemModal(false);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const deferProblem = () => {
        client.deferProblem(problem.id)
            .then(() => {
                setDeferredProblems(prevDeferredProblems => {
                    const existingIndex = prevDeferredProblems.findIndex(p => p.id === problem.id);

                    if (existingIndex >= 0) {
                        const updatedProblems = [...prevDeferredProblems];
                        updatedProblems[existingIndex] = problem;
                        return updatedProblems;
                    } else {
                        return [...prevDeferredProblems, problem];
                    }
                });

                setShowProblemModal(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleReviewClick = (review) => {
        setSelectedReview(review);
        setShowProblemModal(true);
    };

    const handleCloseModal = () => {
        setShowProblemModal(false);
        setSelectedReview(null);
    };

    return (
        <div className='page-container overflow-hidden'>
            <div className='filters-container'>
                <div className='filter-group'>
                    <label htmlFor='theme-filter'>Тема:</label>
                    <select
                        id='theme-filter'
                        value={selectedTheme}
                        onChange={(e) => setSelectedTheme(e.target.value)}
                    >
                        <option value='ANY'>Любой</option>
                        {Object.entries(ProblemTheme).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='filter-group'>
                    <label htmlFor='type-filter'>Тип:</label>
                    <select
                        id='type-filter'
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                    >
                        <option value='ANY'>Любой</option>
                        {Object.entries(ProblemType).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='review-page-header-buttons'>
                <button className='add-problem-button' onClick={getRandomProblem}>
                    Get random problem
                </button>
                <button className='add-problem-button' onClick={() => setShowDeferredPool(true)}>
                    Select from deferred problem's pool
                </button>
                <button className='add-problem-button' onClick={() => setShowReviewHistory(!showReviewHistory)}>
                    My history review
                </button>
            </div>

            {showDeferredPool && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h2>Select a deferred problem</h2>
                        <ul className='problems-list'>
                            {deferredProblems && deferredProblems.length > 0
                                ? deferredProblems.map(problem => (
                                    <li
                                        key={problem.id}
                                        className='problem-card'
                                        onClick={() => handleDeferredProblemSelect(problem)}
                                    >
                                        <div className='problem-theme'>{problem.name}</div>
                                    </li>
                                ))
                                : <p>No deferred problems yet.</p>
                            }
                        </ul>
                        <button className='close-button' onClick={() => setShowDeferredPool(false)}>Закрыть</button>
                    </div>
                </div>
            )}

            {showReviewHistory && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h2 className='review-history-header'>Review History</h2>
                        {userReviewList && userReviewList.length > 0 ? (
                            <ul className='review-history-list'>
                                {userReviewList.map((review, idx) => (
                                    <Link to={`/review/${review.id}`} key={review.id}
                                          onClick={() => handleReviewClick(review)}>
                                        <li className='review-history-item'>
                                            <span>#{idx + 1}: {review.tag}</span>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        ) : (
                            <p>No reviews yet.</p>
                        )}
                        <button className='close-button' onClick={() => setShowReviewHistory(false)}>Close</button>
                    </div>
                </div>
            )}

            {showProblemModal && problem && (
                <div className='modal'>
                    <div className='modal-content' ref={modalContentRef}>
                        <p><strong>Название:</strong> {problem.name}</p>
                        <p><strong>Тема:</strong> {ProblemTheme[problem.theme] || problem.theme}</p>
                        <p><strong>Тип:</strong> {ProblemType[problem.type] || problem.type}</p>
                        <div className='problem-media'>
                            <img
                                src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                                alt="Problem Illustration"
                                className='problem-image'
                            />
                        </div>
                        <AddReviewForm key={problem.id} onSubmit={handleAddReview}/>
                        {!selectedReview && (
                            <div className='review-buttons fullwidth'>
                                <button onClick={deferProblem}>Отложить</button>
                            </div>
                        )}
                        <button className='close-button fullwidth' onClick={handleCloseModal}>Закрыть</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewsPage;
