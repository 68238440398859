import React, {useEffect, useState} from 'react';
import './HomePage.css';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import * as client from '../../../utils/client';
import {Link} from 'react-router-dom';
import AddProblemForm from '../../form/AddProblemForm/AddProblemForm.js';
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";

const HomePage = () => {
    const {telegram} = useTelegram();
    const {jwtToken, user} = useJwt();
    const [loading, setLoading] = useState(true);
    const [problems, setProblems] = useState([]);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        telegram.BackButton.hide();

        client.getUserProblemsByTgId()
            .then(problemsJson => {
                setProblems(problemsJson);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching problems:", error);
                setLoading(false);
            });
    }, [user]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (<div className='page-container'>
        <div className='problem-page-header'>
            <h2>Problems ({problems.length})</h2>
            <button className='add-problem-button' onClick={() => setShowForm(!showForm)}>
                Add Problem
            </button>
        </div>
        <div className='problems-list'>
            {problems && problems.length > 0
                ? (problems.map((problem) =>
                    (<Link to={`/problem/${problem.id}`} key={problem.id} className='problem-card'>
                        <p className='problem-theme'>{problem.name} </p>
                        {problem.coAuthors && problem.coAuthors.length > 0 && (
                            <div className='problem-coauthors'>
                                <p>Co-Authors:</p>
                                {problem.coAuthors.split('\n').map((coAuthor, index) => (
                                    <p key={index} className='coauthor-name'>{coAuthor}</p>
                                ))}
                            </div>
                        )}
                    </Link>)))
                : (<p>No problems found.</p>)}
        </div>

        {showForm
            && <AddProblemForm
                user={user}
                onClose={() => setShowForm(false)}
                onProblemAdded={(newProblem) => setProblems(previousProblems => [...previousProblems, newProblem])}/>
        }
    </div>);
};

export default HomePage;
