const telegram = window.Telegram.WebApp;

export function useTelegram() {
    return {
        telegram: telegram,
        telegramUser: telegram.initDataUnsafe?.user,
        hash: telegram.initDataUnsafe?.hash,
        cloudStorage: telegram.CloudStorage
    };
}
