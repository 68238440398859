import React from 'react';
import './NavBar.css';
import {NavLink} from "react-router-dom";
import {CiChat2, CiViewList} from "react-icons/ci";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import {isAdmin, isPrivilegeUser} from "../../utils/userUtil";
import {FaList, FaUsers} from "react-icons/fa";
import {GrUserAdmin} from "react-icons/gr";
import {useJwt} from "../../context/JwtContext";

const NavBar = () => {
    const {user} = useJwt();
    const [impactOccurred] = useHapticFeedback();

    const NavBarRouter = [
        {
            title: 'Home',
            path: '/',
            icon: <CiViewList/>,
            cName: 'nav-text'
        },
        isPrivilegeUser(user) && {
            title: 'Reviews',
            path: '/reviews',
            icon: <CiChat2/>,
            cName: 'nav-text'
        },
        isAdmin(user) && {
            title: 'Admin',
            path: '/admin',
            icon: <GrUserAdmin/>,
            cName: 'nav-text'
        },
        isAdmin(user) && {
            title: 'Users',
            path: '/users',
            icon: <FaUsers/>,
            cName: 'nav-text'
        },
        isAdmin(user) && {
            title: 'Problems',
            path: '/problems',
            icon: <FaList/>,
            cName: 'nav-text'
        },
    ].filter(Boolean);

    return (
        <nav className='nav-menu'>
            <ul className='nav-menu-items'>
                {NavBarRouter.map((item, index) => (
                    <li key={index} className={item.cName}>
                        <NavLink
                            onClick={() => {
                                impactOccurred('light');
                            }}
                            to={item.path}
                            className={({isActive}) => isActive ? 'nav-text active' : 'nav-text'}
                        >
                            {item.icon}
                            <span>{item.title}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavBar;
