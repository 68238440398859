import React, {useEffect, useState} from 'react';
import './ProblemsPage.css';
import * as client from "../../../utils/client";
import * as constants from "../../../constants/constants";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {Link} from "react-router-dom";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import {isPrivilegeUser} from "../../../utils/userUtil";
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import {FaFileDownload} from "react-icons/fa";

const ProblemsPage = () => {
    const {telegram} = useTelegram();
    const {jwtToken, user} = useJwt();
    const [problems, setProblems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({key: 'averageRating', direction: 'descending'});
    const [filters, setFilters] = useState({theme: 'All', type: 'All'});
    const [searchQuery, setSearchQuery] = useState('');

    const themes = Object.entries(ProblemTheme).map(([key, value]) => ({key, value}));
    const types = Object.entries(ProblemType).map(([key, value]) => ({key, value}));

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    useEffect(() => {
        client.getProblems()
            .then((problemsJson) => {
                setProblems(problemsJson);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    const sortedProblems = React.useMemo(() => {
        let sortableProblems = [...problems];

        if (filters.theme !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.theme === filters.theme);
        }
        if (filters.type !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.type === filters.type);
        }

        if (searchQuery) {
            sortableProblems = sortableProblems.filter(problem =>
                problem.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (sortConfig !== null) {
            sortableProblems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortableProblems;
    }, [problems, sortConfig, filters, searchQuery]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    if (!user || !isPrivilegeUser(user)) {
        return null;
    }

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div className='page-container'>
            <div className='search-bar'>
                <input
                    type="text"
                    placeholder="Поиск по названию задачи..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className='filters'>
                <label>
                    Тема:
                    <select name="theme" value={filters.theme} onChange={handleFilterChange}>
                        <option value="All">Любой</option>
                        {themes.map(({key, value}) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                </label>

                <label>
                    Тип:
                    <select name="type" value={filters.type} onChange={handleFilterChange}>
                        <option value="All">Любой</option>
                        {types.map(({key, value}) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                </label>
            </div>

            <div className="table-responsive">
                <table className='problems-table'>
                    <thead>
                    <tr>
                        <th>Имя Задачи</th>
                        <th onClick={() => requestSort('averageRating')} className='sortable'>
                            Рейтинг
                            {sortConfig.key === 'averageRating' ? (
                                sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                            ) : null}
                        </th>
                        <th>Тема</th>
                        <th>Тип</th>
                        <th>Решение</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedProblems.length > 0 ? (
                        sortedProblems.map(problem => (
                            <tr key={problem.id}>
                                <td>
                                    <Link to={`/problem/${problem.id}`} className='problem-link'>
                                        {problem.name}
                                    </Link>
                                </td>
                                <td>{problem.averageRating}</td>
                                <td>{ProblemTheme[problem.theme] || problem.theme}</td>
                                <td>{ProblemType[problem.type] || problem.type}</td>
                                <td className="download-cell">
                                    <button
                                        className={'downloadSolutionButton'}
                                        onClick={() =>
                                            telegram.openLink(`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}&fileType=SOLUTION`)}>
                                        <FaFileDownload/>
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className='no-data'>Нет задач, соответствующих фильтрам.</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProblemsPage;
