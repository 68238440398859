import React, {useState} from 'react';
import './RegisterPage.css';
import {useTelegram} from "../../../hooks/useTelegram";
import * as client from "../../../utils/client";

const RegisterPage = () => {
    const {telegramUser, cloudStorage, hash} = useTelegram();
    const [error, setError] = useState(null);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [userCredentials, setUserCredentials] = useState({
        tgId: telegramUser?.id || '',
        username: telegramUser?.username || '',
        firstName: '',
        lastName: '',
        email: '',
        hash: hash
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setUserCredentials((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isTermsAccepted) {
            setError('You must accept the terms and conditions.');
            return;
        }

        client.registerUser(userCredentials)
            .then((jwtToken) => {
                cloudStorage.setItem("jwt", jwtToken);
                window.location.reload();
            })
            .catch(error => {
                setError(error.response.data);
            });
    };

    return (
        <div className="register-page">
            <h2>Register</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name:</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={userCredentials.firstName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name:</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={userCredentials.lastName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={userCredentials.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    />
                    <label htmlFor="terms">
                        I accept the <a href="/terms.pdf" target="_blank" rel="noopener noreferrer">Terms and
                        Conditions</a>
                    </label>
                </div>
                {error && <p className="error">{error}</p>}
                {isTermsAccepted && <button type="submit" disabled={!isTermsAccepted}>Register</button>}
            </form>
        </div>
    );
};

export default RegisterPage;
