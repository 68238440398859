import React, {useEffect, useState} from 'react';
import './ProblemPage.css';
import {useNavigate, useParams} from 'react-router-dom';
import * as client from '../../../utils/client';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {useTelegram} from "../../../hooks/useTelegram";
import * as constants from "../../../constants/constants";
import {useJwt} from "../../../context/JwtContext";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";

const ProblemPage = () => {
    const {jwtToken, user} = useJwt();
    const {problemId} = useParams();
    const {telegram} = useTelegram();
    const navigate = useNavigate();
    const [problem, setProblem] = useState(null);
    const [loading, setLoading] = useState(true);

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getProblemById(problemId)
            .then(problemJson => {
                setProblem(problemJson);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching problem:", error);
                setLoading(true);
            });
    }, [problemId]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div className='fullHeight-page page-container'>
            <div className='problem-details'>
                <div className='problem-page-header'>
                    <p className='problem-theme'>
                        Тема: {ProblemTheme[problem.theme] || problem.theme} |
                        Тип: {ProblemType[problem.type] || problem.type}
                    </p>
                    <p className='problem-theme'>Название: {problem.name} </p>
                    {user.id !== problem.author.id
                        && <p className='problem-theme'>Рейтинг: {problem.averageRating} </p>}
                </div>
                {problem.coAuthors && problem.coAuthors.length > 0 && (
                    <div className='problem-coauthors'>
                        <p>Соавторы:</p>
                        {problem.coAuthors.split('\n').map((coAuthor, index) => (
                            <p key={index} className='coauthor-name'>{coAuthor}</p>
                        ))}
                    </div>
                )}
                <div className='problem-media'>
                    <img
                        src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                        alt="Problem Illustration"
                        className='problem-image'
                    />
                </div>
            </div>
        </div>
    );
};

export default ProblemPage;
