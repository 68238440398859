import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {JwtProvider, useJwt} from "./context/JwtContext";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./components/page/HomePage/HomePage";
import ReviewsPage from "./components/page/ReviewsPage/ReviewsPage";
import ProblemPage from "./components/page/ProblemPage/ProblemPage";
import AdminPage from "./components/page/AdminPage/AdminPage";
import RegisterPage from "./components/page/RegisterPage/RegisterPage";
import UsersPage from "./components/page/UsersPage/UsersPage";
import ReviewPage from "./components/page/ReviewPage/ReviewPage";
import ProblemsPage from "./components/page/ProblemsPage/ProblemsPage";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import {isAdmin, isPrivilegeUser} from "./utils/userUtil";
import {useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";

function AppContent() {
    const {jwtToken, user, loading, authError} = useJwt();

    if (loading) {
        return <LoadingSpinner/>;
    }

    if (authError) {
        return (
            <div className="auth-error-container">
                <h2>Ошибка авторизации</h2>
                <p>{authError}</p>
                <button onClick={() => window.location.reload()}>Повторить</button>
            </div>
        );
    }

    return (<Router>
            {jwtToken && user
                ? (<><NavBar />
                    <Routes>
                        <Route path="/" element={<HomePage />}/>
                        <Route path="/problem/:problemId" element={<ProblemPage />}/>

                        {isPrivilegeUser(user) && (
                            <>
                                <Route path="/reviews" element={<ReviewsPage />}/>
                                <Route path="/review/:reviewId" element={<ReviewPage />}/>
                            </>
                        )}

                        {isAdmin(user) && (
                            <>
                                <Route path="/users" element={<UsersPage />}/>
                                <Route path="/problems" element={<ProblemsPage />}/>
                                <Route path="/admin" element={<AdminPage />}/>
                            </>
                        )}
                    </Routes>)
                </>)
                : (<RegisterPage/>)}
        </Router>
    );
}

function App() {
    const {telegram} = useTelegram();

    useEffect(() => {
        telegram.ready();
        telegram.expand();
        telegram.disableVerticalSwipes();
    }, [telegram]);

    return (
        <JwtProvider>
            <AppContent/>
        </JwtProvider>
    );
}

export default App;
